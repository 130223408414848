import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "components/layout/layout"
import Seo from "components/layout/seo"

import ImgBackground from "components/heroes/ImgBackground"
import TitleBox from "components/titles/Titlebox"

import ContactForm from "../components/forms/contact"

const Contact = () => {

  // using static header image, load here
  const data = useStaticQuery(
    graphql`
    query {
      heroImage: file(relativePath: { eq: "backgrounds/ship-bow.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1200
          )
        }
      }
    }
  `
  )

  return (
    <Layout>
      <Seo title="Contact Us" />

      {/* Title banner */}
      <ImgBackground image={data.heroImage}>
        <div className="container m-auto py-10 lg:pb-0 md:pt-40 lg:pt-60 lg:flex">
          <TitleBox
            title="Would you like to get in touch?"
            caption="Contact Us"
          >
          </TitleBox>
        </div>
      </ImgBackground>



      {/* Content Area */}
      <section className="page--content py-32">
        <div className="container lg:flex">
          <div className="w-full mb-16 lg:w-2/5 lg:order-2 prose prose-sm">

            <h3 className="my-2">USA</h3>
            <p>
              8 The Green, Suite #10212. Dover.<br />
              Delaware 19901. USA<br />
              Tel: (302) 601-4659<br />
              info@pacificgreen.tv</p>

            <h3 className="mb-2">UK</h3>
            <p>4 Albemarle Street, Mayfair, London. W1S 4GA<br />
            Tel: +44 (0)20 3988 3399<br />
            london@pacificgreen.tv</p>

            <h3 className="my-2">Canada</h3>
            <p>204-275 Fell Avenue, North Vancouver, BC V7P 3R5 Canada<br />
            vancouver@pacificgreen.tv</p>

            <h3 className="my-2">China</h3>
            <p>No.158 Jiyang Road, New Bund World Trade Center, B Block 20F, Shanghai, China<br />
            shanghai@pacificgreen.tv</p>

            <h3 className="mb-2">Saudi Arabia</h3>
            <p>Frimex Plaza, 9105 Ibrahim Al-Juffali Street,<br />
              Al-Andalus District, Jeddah 23326-3764. Saudi Arabia<br />
              jeddah@pacificgreen.tv</p>

            <h3 className="mb-2">Spain</h3>
            <p>Paseo de la Castellana 18, 7th Floor. 28046 Madrid. Spain<br />
            madrid@pacificgreen.tv</p>

          </div>
          <div className="w-full lg:w-3/5 lg:pr-16">
            <div className="bg-primary-50 rounded p-16">
              <h3 className="mb-4">Send a message</h3>
              <ContactForm />
            </div>
          </div>

        </div>
      </section>


    </Layout>
  )
}

export default Contact
