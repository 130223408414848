import React from "react"
import { navigate } from "gatsby-link"
import { useForm } from "react-hook-form"


function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactForm = () => {

  const { register, formState: { errors }, handleSubmit } = useForm({
    mode: 'onBlur',
  });

  // const [state, setState] = React.useState({})
  // const handleChange = e =>
  //     setState({ ...state, [e.target.name]: e.target.value })

  const onSubmit = (data, e) => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        'form-name': 'contact',
            ...data,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => console.log(error))
  }

  const formItemClass =
    "mt-1 block w-full rounded-sm border-gray-300 shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-300 focus:ring-opacity-50"

  return (
    <form
      name="contact"
      method="post"
      action="/thanks/"
      data-netlify="true"
      data-netlify-honeypot="botfield"
      onSubmit={handleSubmit(onSubmit)}
      className="w-full"
    >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="contact" />

      <div hidden>
        <label>
          Not for humans: <input name="botfield" {...register("botfield")} />
        </label>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">

        <label className="block">
          <span className="text-sm text-gray-700">Full name *</span>
          <input
            type="text"
            name="name"
            {...register("name", { required: true })}
            className={formItemClass}
          />
          <span className="text-xs text-primary">{errors.name?.type === 'required' && "Full name is required"}</span>
        </label>

        <label className="block">
          <span className="text-sm text-gray-700">Email address *</span>
          <input
            type="email"
            name="email"
            {...register("email", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
            className={formItemClass}
          />
          <span className="text-xs text-primary">{errors.email?.type && "Your email is required"}</span>
        </label>

        <label className="block">
          <span className="text-sm text-gray-700">Company Name</span>
          <input
            type="text"
            name="company"
            {...register("company")}
            className={formItemClass}
          />
        </label>

        <label className="block">
          <span className="text-sm text-gray-700">Interested in</span>
          <select
            name="interests"
            {...register("interests")}
            className={formItemClass}
          >
            <option value="general">General enquiry</option>
            <option value="refinery">Scrubber project enquiry</option>
            <option value="marine">Aftersales support</option>
          </select>
        </label>

        <label className="block lg:col-span-2">
          <span className="text-sm text-gray-700">Message *</span>
          <textarea
            name="message"
            {...register("message", { required: true })}
            className={formItemClass}
            rows="5"
          ></textarea>
          <span className="text-xs text-primary">{errors.message?.type && "Please let us know a little about your interest in messaging us"}</span>
        </label>
      </div>
      <div className="my-4">
        <button type="submit" className="btn-primary mt-8 px-8">
          Send
        </button>
      </div>
    </form>
  )
}

export default ContactForm
